import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/_layouts/navbar/navbar.component';
import { MainComponent } from './layouts/main/main.component';
import { FooterComponent } from './components/_layouts/footer/footer.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { WorkplaceComponent } from './pages/workplace/workplace.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TrainingComponent } from './pages/training/training.component';
import { MediaComponent } from './pages/media/media.component';
import { ComparisonComponent } from './pages/comparison/comparison.component';
import { VirtualClassroomComponent } from './pages/virtualclassroom/virtualclassroom.component';
import { LandingComponent } from './pages/landing/landing.component';
import { TechnologyComponent } from './pages/technology/technology.component';
import { PlatformComponent } from './pages/platform/platform.component';
import { OurglobalnetworkComponent } from './pages/ourglobalnetwork/ourglobalnetwork.component';
import { RegulatoryComplianceComponent } from './pages/regulatorycompliance/regulatoryCompliance.component';
import { ConsultingComponent } from './pages/consulting/consulting.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { TradeComponent } from './pages/trade/trade.component';
import { ManufacturingComponent } from './pages/manufacturing/manufacturing.component';
import { MembershipComponent } from './pages/membership/membership/membership.component';
import { MembershipComparisonComponent } from './pages/membership/membership-comparison/membership-comparison.component';
import { CertificationComponent } from './pages/certification/certification.component';
import { CertficationdetailsComponent } from './pages/certficationdetails/certficationdetails.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { GainingComponent } from './pages/gaining/gaining.component';
import { CertificationStandardsComponent} from './pages/certification-standards/certification-standards.component';
import { CertificationServicesComponent} from './pages/certification-services/certification-services.component';
import { CertificationBenefitComponent} from './pages/certification-benefit/certification-benefit.component';
import { CertificationHowItWorksComponent} from './pages/certification-how-it-works/certification-how-it-works.component';
import { CertificationMethodologyComponent} from './pages/certification-methodology/certification-methodology.component';
import { IndividualCertificationComponent} from './pages/individual-certification/individual-certification.component';
import { IndividualCertificationComparisonComponent } from './pages/individual-certification-comparison/individual-certification-comparison.component';
import { IndividualCertificationDetailsComponent } from './pages/individual-certification-details/individual-certification-details.component';
import { ExecutiveCoachingComponent } from './pages/executive-coaching/executive-coaching.component';
import { SurveyFormsModule } from './pages/survey-forms/survey-forms.module';
import { SurveyComponent } from './components/shared/survey/survey.component';
import { ViewFormComponent } from './pages/survey-forms/view-form/view-form.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    MainComponent,
    FooterComponent,
    AboutUsComponent,
    FinancialComponent,
    WorkplaceComponent,
    ContactComponent,
    TrainingComponent,
    MediaComponent,
    ComparisonComponent,
    VirtualClassroomComponent,
    LandingComponent,
    TechnologyComponent,
    PlatformComponent,
    OurglobalnetworkComponent,
    RegulatoryComplianceComponent,
    ConsultingComponent,
    ReportsComponent,
    TradeComponent,
    ManufacturingComponent,
    MembershipComponent,
    MembershipComparisonComponent,
    CertificationComponent,
    CertficationdetailsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    GainingComponent,
    CertificationStandardsComponent,
    CertificationServicesComponent,
    CertificationBenefitComponent,
    CertificationHowItWorksComponent,
    CertificationMethodologyComponent,
    IndividualCertificationComponent,
    IndividualCertificationComparisonComponent,
    IndividualCertificationDetailsComponent,
    ExecutiveCoachingComponent,
    SurveyComponent,
    ViewFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SurveyFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
